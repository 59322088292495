<div class="d-flex flex-column">
    <div class="d-flex justify-content-center">
        <img src="http://s.oab.org.br/imagens/marcas-sistemas/mono-positiva/2x/bloco-assinaturas.png" alt="Logo">
    </div>

    <div class="d-flex justify-content-center mt-3">
        <div class="alert alert-primary" role="alert">
            <h4 class="alert-heading">Acesso negado!</h4>
        </div>
    </div>
</div>