import { Component } from '@angular/core';

@Component({
  templateUrl: './page-403.html'
})
export class Page403 {

  constructor() {}

}

