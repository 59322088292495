import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [

    {
        path: '',
        data: {
            title: 'Bloco de Assinatura'
        },
        loadChildren: () => import('../../modules/admin/bloco-assinatura-admin.module').then(m => m.BlocoAssinaturaAdminModule),
    },
    {
        path: 'caixa-entrada',
        data: {
            title: 'Bloco de Assinatura'
        },
        loadChildren: () => import('../../modules/publico/bloco-assinatura-publico.module').then(m => m.BlocoAssinaturaPublicoModule),
    },
];