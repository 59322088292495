import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Page403 } from "./403/page-403";

const routes: Routes = [{
    path: 'pages',
    children: [
        { path: '403', component: Page403 }
    ]
}]

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        Page403,
    ]
})
export class PagesModule { }