import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { CookieService } from 'ngx-cookie-service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';

@NgModule({
    declarations: [],
    exports: [LoadingBarHttpClientModule],
    imports: [
        HttpClientModule,
        LoadingBarHttpClientModule,
        LayoutsModule,
        PagesModule,
        NgxWebstorageModule.forRoot(),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_I18N, useValue: pt_BR },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        CookieService
    ]
})

export class CoreModule { }
