<div class="common-layout">
    <app-header [showNavToggle]="false"></app-header>
    <!-- <app-sidenav></app-sidenav> -->
    <div class="page-container" style="padding-left:0%">
        <div class="main-content">
             <div class="main-content-header" *ngIf="contentHeaderDisplay !== 'none'">
                <h4 class="page-title" *ngIf="breadcrumbs$ | async; let breadcrumbs">{{(breadcrumbs[breadcrumbs.length - 1].label)}}</h4>
               <!-- <nz-breadcrumb nzSeparator=">">
                    <i class="m-r-5 text-gray" nz-icon nzType="home"></i>
                    <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs$ | async">
                        <a [routerLink]="breadcrumb.url">
                            {{ breadcrumb.label }}
                        </a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb> -->
            </div> 
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>