import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { FooterComponent } from './template/footer/footer.component';
import { HeaderComponent } from './template/header/header.component';
import { SideNavComponent } from './template/side-nav/side-nav.component';


@NgModule({
    declarations: [
        CommonLayoutComponent,
        BlankLayoutComponent,
        PublicLayoutComponent,
        HeaderComponent,
        HeaderComponent,
        SideNavComponent,
        FooterComponent
    ],
    imports: [
       CommonModule,
       RouterModule,
       NzAvatarModule,
       NzIconModule,
       NzDropDownModule,
       PerfectScrollbarModule,
       NzMessageModule
    ],
    providers: []
})

export class LayoutsModule { }
