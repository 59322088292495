import { Component, Input } from '@angular/core';
import { ThemeConstantService } from '../../theme-constant.service';
import { UsuarioAutenticadoService } from '../../../services/usuario-autenticado.service';
import { TUTORIAL_URL} from "../../../../shared/constantes";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent {

    @Input() showNavToggle = true;
    searchVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;
    avatar: string;

    usuarioAutenticado$ = this.usuarioService.getUsuarioAutenticado();

    constructor( 
        private themeService: ThemeConstantService,
        private usuarioService: UsuarioAutenticadoService
    ) {}

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    logout() {
        this.usuarioService.logout();
    }

    trocarSetor() {
        this.usuarioService.trocarSetor();
    }

    openTutorial(){
        window.open(TUTORIAL_URL, "_blank");
    }


}
