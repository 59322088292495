import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoRouteService } from './core/guards/autenticacao-route.service';
import { CommonLayoutComponent } from "./core/layouts/common-layout/common-layout.component";
import { PublicLayoutComponent } from './core/layouts/public-layout/public-layout.component';
import { CommonLayout_ROUTES } from "./core/routes/common-layout.routes";
import { PublicLayout_ROUTES } from './core/routes/public-layout.routes';

const appRoutes: Routes = [
    { 
        path: 'bloco-assinatura', 
        component: PublicLayoutComponent,
        children: PublicLayout_ROUTES,
        canActivate: [ AutenticacaoRouteService ]
    },
    { 
        path: 'admin/bloco-assinatura', 
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [ AutenticacaoRouteService ]
    },
    { 
        path: '', 
        redirectTo: 'bloco-assinatura',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { 
            useHash: false,
            scrollPositionRestoration: 'enabled' 
        })
    ],
    exports: [
       RouterModule
    ]
})

export class AppRoutingModule {
}