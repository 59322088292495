import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { Observable } from "rxjs";
import { CS_ORGANIZACIONAL_URL } from "../../shared/constantes";
import { UsuarioAutenticadoService } from "../services/usuario-autenticado.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private usuarioService: UsuarioAutenticadoService,
    private msg: NzMessageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clone = req; 

    if(req.url.includes(CS_ORGANIZACIONAL_URL)) {
      clone = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.usuarioService.getCookieValue()}`)
      });
    }

    return next.handle(clone);
  }

}