import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { UsuarioAutenticadoService } from "../services/usuario-autenticado.service";

@Injectable({
    providedIn: "root"
})
export class AutenticacaoRouteService implements CanActivate {

    constructor(
        private usuarioService: UsuarioAutenticadoService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.usuarioService.isAuthenticated();
    }

}