import { Routes } from '@angular/router';


export const PublicLayout_ROUTES: Routes = [

    {
        path: '',
        data: {
            title: 'Bloco de Assinatura'
        },
        loadChildren: () => import('../../modules/publico/bloco-assinatura-publico.module').then(m => m.BlocoAssinaturaPublicoModule),
     }
];