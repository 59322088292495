import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/admin/bloco-assinatura/caixa-entrada',
        title: 'Caixa de Entrada',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    },
    {
        path: '/admin/bloco-assinatura',
        title: 'Gerenciar Blocos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        submenu: []
    },
   
]    